import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createVNode(_component_router_view, null, {
      default: _withCtx(({ Component }) => [
        _createVNode(_Transition, {
          name: "route",
          mode: "out-in"
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
          ]),
          _: 2
        }, 1024)
      ]),
      _: 1
    })
  ]))
}